.signup-message {
  &__body {
    text-align: center;
    margin-bottom: 70px;
  }

  &__cause {
    background-color: $color-white;
    border: 1px solid $color-alto;
    padding: 5px 20px;
    font-size: $font-size-large;
    font-weight: $font-weight-normal;
    border-bottom: 1px solid $color-grey-light;
    margin: 0 0 10px;
  }
}
