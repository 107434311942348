/**
* Width
*/
.u-w255 {
  width: 255px;
}

.u-w125 {
  width: 125px;
}

.u-full-width {
  width: 100%;
}

/**
* Text
*/
.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-text-small {
  font-size: $font-size-small;
}

.u-text-medium {
  font-size: $font-size-large;
}

/**
* Margin top
*/
.u-mt-3 {
  margin-top: 30px;
}

.u-mt-2 {
  margin-top: 20px;
}

.u-mt-6 {
  margin-top: 60px;
}

/**
* Margin left
*/
.u-ml-1 {
  margin-left: 10px;
}

.u-ml-2 {
  margin-left: 10px;

  @include tablet {
    margin-left: 20px;
  }
}

.u-ml-3 {
  margin-left: 30px;
}

/**
* Margin right
*/
.u-mr-1 {
  margin-right: 10px;
}

.u-mr-2 {
  margin-right: 16px;

  @include tablet {
    margin-right: 27px;
  }
}

.u-mr-3 {
  margin-right: 30px;
}

/**
* Margin bottom
*/
.u-mb-1 {
  margin-bottom: 10px;
}

.u-mb-2 {
  margin-bottom: 20px;
}

.u-mb-3 {
  margin-bottom: 30px;
}

/**
* Margin x-axis
*/
.u-my-3 {
  margin: 30px 0;
}

/**
* Padding x-axis
*/
.u-px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

/**
* Padding y-axis
*/
.u-p-3 {
  padding: 30px;
}

.u-py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.u-py-2 {
  padding: 20px 0;
}

.u-py-3 {
  padding: 30px 0;
}

.u-pt-3 {
  padding-top: 30px;
}

.u-pl-2 {
  padding-left: 20px;
}

.u-pr-3 {
  padding-right: 30px;
}

/**
* Border
*/
.u-border {
  border: 1px solid $color-border;
}

.u-border-bottom {
  border-bottom: 1px solid $color-white;
}

.u-border-bottom--grey {
  border-bottom: 1px solid $color-border;
}

.u-border-top {
  border-top: 1px solid $color-white;
}

.u-colon {
  &::after {
    content: ':';
    position: relative;
    top: -1.5px;
    left: 5px;
  }
}

.u-sm-w100 {
  width: 100px;
  min-width: 100px;

  @include tablet {
    width: initial;
    min-width: 180px;
  }
}

.u-sm-hidden {
  display: none;

  @include tablet {
    display: block;
  }
}

.u-md-hidden {
  display: block;

  @include tablet {
    display: none;
  }
}

.u-sm-margin-bottom-2 {
  margin-bottom: 20px;

  @include tablet {
    margin-bottom: 0;
  }
}

.u-mark {
  color: $color-mark;
  font-weight: normal;
}

.u-mobile {
  display: block;
  width: 100%;
  margin-top: 10px;

  @include tablet {
    margin-top: 0;
    display: inline;
    width: initial;
  }
}

.u-no-padding {
  padding: 0 !important;
}

.u-d-block {
  display: block !important;
}

/**
* Disabled on mobile
*/
.u-mb-disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;

  @include tablet {
    opacity: 1;
    cursor: initial;
    pointer-events: initial;
  }
}

.u-text-danger {
  color: $color-text-danger !important;
}

.u-flag {
  background-color: #ff9257;
  color: $color-white;
  font-size: $font-size-smallest;
  font-weight: $font-weight-normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  padding: 4px 7px;

  @include tablet {
    padding: 5px 15px;
    font-size: $font-size-small;
  }
}

.u-link {
  cursor: pointer;
  color: $color-link;
}

.u-over-hidden {
  overflow: hidden !important;
}
