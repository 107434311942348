// Components :: Icon
// --------------------------------------------------
.icon {
  &--right {
    margin-left: 5px;
  }

  &--left {
    padding-right: 5px;
  }
}
