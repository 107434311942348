.link {
  display: inline-block;
  color: $color-link;
  cursor: pointer;

  &--block {
    display: block;
  }

  &--grey {
    color: $color-grey;
  }

  &--underline {
    text-decoration: underline;
  }

  &--text-medium {
    font-size: $font-size-small;
  }
}
