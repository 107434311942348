.notify-list {
  &__name {
    font-size: $font-size-larger;
    padding: 40px 0;
  }

  &__name-sub {
    font-size: $font-size-extra-large;
    padding: 5px 15px;
    margin-bottom: 40px;
    font-weight: $font-weight-normal;
    background-color: $color-background-gray;
  }

  &__link {
    font-size: $font-size-small;
    text-decoration: none;
  }

  &__link:hover {
    text-decoration: underline;
  }

  &__notify {
    font-size: $font-size-small;
  }

  &__date {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  &__info {
    margin-bottom: 60px;
  }

  &__item {
    font-size: $font-size-small;
    text-decoration: none;
  }

  &__item:hover {
    text-decoration: underline;
  }

  &__item--margin {
    display: block;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-grey-light;
  }
}
