/**
 * Abstracts :: Variables
 */

/**
 * Color
 */

$color-white: #fff;
$color-grey: #333;
$color-suva-grey: #929292;
$color-text-grey: #767676;
$color-grey-light: #ccc;
$color-error: #d00;
$color-link: #1847C7;
$color-dusty-gray: #999;
$color-alto: #ddd;
$color-black: #000;
$color-background-gray: #dedede;
$color-wild-blue-yonder: #8788b5;
$color-mercury: #e7e7e7;
$color-spring-rain: #aca;
$color-primary: #1847c7;
$color-dark-white: #f6f6f6;
$color-orange: #ff6000;
$color-silver: #bfbfbf;
$color-gray: #e3e3e3;
$color-red: #db3333;
$color-french-gray: #bbbbbd;
$color-concrete: #f3f3f3;
$color-denim: #5281ff;
$color-blue: #0080ff;
$color-silver-chalice: #a4a4a4;
$color-dodger-blue: #0080ff;
$color-hint: $color-silver-chalice;
$color-border: #c7c7c7;
$color-sub-title: $color-text-grey;
$color-mark: $color-silver-chalice;
$color-bg-footer: #3a3a3a;
$color-text-danger: $color-red;
$color-hover-backgroud: #f2f2f2;

/**
 * Typography
 */

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-size-smallest: 1rem;
$font-size-smaller: 1.2rem;
$font-size-small: 1.4rem;
$font-size-base: 1.6rem;
$font-size-large: 1.8rem;
$font-size-extra: 2rem;
$font-size-extra-large: 2.2rem;
$font-size-big: 2.4rem;
$font-size-larger: 3rem;
$font-size-largest: 3.2rem;
$font-size-biggest: 3.4rem;
$font-size-title: $font-size-big;
$font-size-sub-title: $font-size-extra;
$font-size-label: $font-size-small;
$font-size-button: $font-size-base;
$font-size-body: $font-size-base;
$font-size-hint: $font-size-small;
$font-size-button: 1.5rem;
$font-family-primary: 'Noto Sans Japanese', sans-serif;
$font-family-primary: 'Noto Sans Japanese', sans-serif;

/**
* Media queries breakpoints
*/

$screen-sm: 768px !default;

/**
* Z-index master list
* Warning: Avoid customizing these values. They're used for a bird's eye view
*/

$zindex-fixed: 1030 !default;
$zindex-header: $zindex-fixed;
$zindex-menu-bar: $zindex-fixed;
