.user {
  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &__name {
    max-width: 150px;
    display: inline-block;
    padding: 0 13px;

    @extend %truncate;
  }

  &__link {
    position: relative;
  }
}
