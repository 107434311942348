.section {
  &__sub {
    padding: 5px 15px;
    margin-bottom: 20px;
    background-color: $color-background-gray;
    font-weight: $font-weight-normal;

    @include tablet {
      font-size: $font-size-sub-title;
      margin-bottom: 40px;
    }
  }

  &__title-table {
    margin-bottom: 0;
  }
}
