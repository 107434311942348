// Page :: History of payment
// --------------------------------------------------

.payment-history {
  &__main-content {
    margin-bottom: 40px;
  }

  &__main-title {
    font-size: $font-size-larger;
    padding: 40px 0;
  }

  .base-table {
    &__button {
      width: 150px;
    }
  }

  &__dropdown {
    max-width: 200px;
    margin-bottom: 20px;
  }
}
