@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.item {
  border-bottom: 1px solid $color-silver;
  height: 54px;
  width: 100%;
  position: relative;

  .itemInner {
    padding: 0 20px;
    color: $color-grey;
    transition: background-color 0.25s ease-out;
    display: flex;
    align-items: center;

    i {
      font-size: $font-size-big;
      max-width: 24px;
    }

    &:hover {
      background: rgba($color-black, 0.05);
    }

  }

  .itemText {
    display: inline-block;
    line-height: 54px;
    font-size: $font-size-base;
    padding-left: 20px;

    @extend %truncate;
  }
}
