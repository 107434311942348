@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.spMenuToggle {
  display: inline-block;
  color: $color-white;
  background: transparent;
  padding: 0;

  @include tablet {
    display: none;
  }

  .innerWapper {
    position: relative !important;

    .avatarBox {
      position: relative;
      text-align: center;
      display: inline-block;
      border-radius: 5px;
      padding: 2px;

      &:hover {
        background: $color-hover-backgroud;
      }
    }
  }
}
