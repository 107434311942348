@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.header {
  width: 100%;
  position: fixed;
  background-color: $color-white;
  line-height: 60px;
  max-height: 60px;
  padding: 5px 0;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
  z-index: $zindex-header;

  .content {
    height: 44px;

    @include tablet {
      height: 50px;
    }
  }
}
