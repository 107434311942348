.checkbox {
  display: flex;
  align-items: baseline;

  &__label {
    text-align: left;
    line-height: 24px;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
