
@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.loginButton {
  line-height: 1;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  background: $color-white;
  color: $color-link;
  margin-left: 8px;
  text-align: center;
  border: solid 1px $color-primary;
  -webkit-transition: 0.3s ease-out;
  transition: all 0.3s ease;
  min-width: 150px;
  padding: 15px;
  font-size: initial;

  &:hover {
    opacity: 0.5;
  }
}

.menuIcon {
  color: $color-grey;
  width: 24px;
  height: 24px;
}

.menuBox {
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  padding: 2px 5px;

  i {
    font-size: $font-size-big;
  }

  &:hover {
    background: $color-hover-backgroud;
  }
}
