.breadcrumbs {
  display: inline-block;
  margin-bottom: 28px;

  &__section {
    width: 48px;
    height: 24px;

    > a {
      cursor: pointer;
      margin-right: 24px;
      color: $color-blue;
      font-size: $font-size-smaller;
    }

    &:hover {
      > a {
        color: $color-link;
      }
    }
  }

  &__separator {
    margin-right: 24px;
  }
}
