@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('https://fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2');
  font-weight: 700;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-family-primary;
  font-weight: $font-weight-normal;
  font-size: $font-size-body;
  padding: 0;
  margin: 0;
  color: $color-grey;
  font-feature-settings: 'palt';
  background: $color-dark-white;
}

h1,
h2,
h3,
h4,
h5,
p,
div,
section {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p,
div {
  font-size: 1.5rem;
  line-height: 1.5;

  @include tablet {
    font-size: $font-size-base;
  }
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  border-radius: 0;
  border: none;
  cursor: pointer;
}

input,
textarea {
  &::placeholder {
    opacity: 0.4;
  }
}

textarea {
  line-height: 1.5;
  overflow: auto;
  resize: vertical;
}

.disabled,
.btn[disabled='disabled'] {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.btn[disabled='disabled'].default:hover {
  background-color: $color-link;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

a {
  color: $color-link;
}

.text-left {
  text-align: left;
}

.whitespace-preserved {
  white-space: pre-wrap;
}

.js-overflow-hidden {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
}
