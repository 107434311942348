.contact {
  padding: 0;
  background-size: 100%;
  min-width: 1000px;
  margin: 0 auto;

  &__input-name {
    width: 58%;
  }

  .form__wrapper {
    margin-bottom: 40px;
  }

  .input-area__input {
    width: 50%;
    padding-left: 10px;
  }
}
