.product-list {
  width: 100%;
  font-size: $font-size-small;

  .table {
    &__title {
      font-size: $font-size-extra-large;
      padding: 5px 15px;
      font-weight: $font-weight-normal;
      background-color: $color-background-gray;
    }

    &__number {
      font-size: $font-size-small;
    }

    &__info {
      text-align: right;
      padding-top: 5px;
    }

    &__price--old {
      color: $color-error;
      text-decoration: line-through;
    }

    &__select {
      transition: all 1s;

      .input {
        display: none;
      }

      .link {
        width: 100%;
        padding: 15px;
        text-decoration: underline;
      }

      &:hover {
        background-color: $color-link;

        .link {
          color: $color-white;
        }
      }

      &--selected {
        background-color: $color-link;
        color: $color-white;

        .link {
          color: $color-white;
        }
      }

      &--during-use {
        background-color: $color-wild-blue-yonder;
        color: $color-white;
        padding: 15px;
      }
    }
  }

  &__freeday {
    font-size: $font-size-extra;
    color: $color-red;
  }
}
