
@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.menuIcon {
  color: $color-grey;
  width: 24px;
  height: 24px;
}

.menuBox {
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  padding: 2px 5px;

  i {
    font-size: $font-size-big;
  }
}


.menuLabel {
  display: none;
  font-size: 10px;
  color: $color-silver-chalice;
  min-width: 75px;

  @include tablet {
    display: block;
  }
}

.notificationNumber {
  position: absolute;
  top: 0;
  right: -8px;
  color: $color-white;
  width: 18px;
  height: 18px;
  background: $color-orange;
  border-radius: 50%;
  border: 1px solid $color-white;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 9px;

  @include tablet {
    right: 14px;
    width: 18px;
    height: 18px;
    line-height: 1.6;
    font-size: 10px;
  }
}
