.term {
  &__numbering {
    &--level-1 {
      font-size: $font-size-small;
      padding: 30px 0 10px;
      font-weight: $font-weight-bold;
    }

    &--level-2 {
      margin-left: 1em;
      padding-left: 0;
      padding-bottom: 10px;
      list-style-type: decimal;
    }
  }

  &__content {
    padding: 20px;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    background-color: $color-gray;
    line-height: 1.7;
  }

  &__accept {
    font-size: 100%;
    color: $color-denim;
    text-align: center;
  }

  &__title-article {
    font-size: $font-size-base;
    padding-bottom: 10px;
    color: $color-link;
  }

  &__sub-title {
    color: $color-grey;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
  }

  p {
    @include tablet {
      font-size: $font-size-small;
    }
  }

  .ReactCollapse--collapse {
    border: 0.5px solid rgb(0 0 0 / 0%);
    transition: height 500ms;
  }
}
