.home {
  &__head {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  &__link {
    a {
      text-decoration: underline;
    }
  }

  &__desc {
    p {
      margin-bottom: 0.5em;
    }

    br {
      display: none;
    }

    @include tablet {
      text-align: center;
      font-size: $font-size-large;
      line-height: 1.7;

      p {
        margin-bottom: 0;
        line-height: 1.7;
        font-size: $font-size-large;
      }

      br {
        display: initial;
      }
    }
  }
}
