.remind {
  text-align: right;
  font-size: $font-size-small;
  padding: 10px 0 0;

  &__link {
    color: $color-grey;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    i {
      text-decoration: none;
    }
  }
}
