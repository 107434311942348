.band {
  background: $color-white;
  border: 1px solid $color-gray;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 16px;

  @include tablet {
    padding: 30px;
    margin-bottom: 16px;
  }

  &__thumb {
    align-items: stretch;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    height: 120px;
    width: 100%;

    @include tablet {
      flex-basis: 307px;
      width: 307px;
      height: auto;
      flex-direction: row;
    }

    &--relative {
      position: relative;

      &__multi-thum {
        width: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        &:first-child {
          left: 0;
          right: auto;
        }
      }
    }
  }

  &--flex {
    display: flex;
    padding: 0;
    flex-wrap: wrap;

    @include tablet {
      flex-wrap: inherit;
    }
  }

  &--noborder {
    border: 0;
  }

  &--small {
    padding: 14px 30px;
    margin-bottom: 10px;
  }

  &__content {
    padding-bottom: 20px;

    @include tablet {
      padding-bottom: 40px;
    }
  }

  &__text {
    line-height: 30px;
  }

  &__subject {
    padding-right: 20px;
  }

  &__sub-title {
    padding-bottom: 20px;
    font-size: $font-size-large;
  }

  &__danger {
    color: $color-red;
  }

  &__slash {
    padding: 0 10px;
  }

  &__price {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-weight: bold;

    @include tablet {
      margin-top: 0;
      display: inline-block;
      width: initial;
      line-height: 30px;
    }
  }

  &__renewal {
    padding-top: 15px;

    @include tablet {
      padding-top: 20px;
    }
  }

  &__body {
    margin-bottom: 15px;

    @include tablet {
      margin-bottom: 0;
      flex-grow: 1;
    }
  }

  &__full {
    width: 100%;

    .grid__mrdbt40:first-child {
      margin-bottom: 20px;

      @include tablet {
        margin-bottom: 35px;
      }
    }
  }

  &__update {
    width: 100%;

    @include tablet {
      position: absolute;
      top: 20px;
      right: 32px;
      width: auto;
    }
  }

  &__relative {
    position: relative;
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > .btn {
      flex: 1;
      margin-bottom: 10px;

      @include tablet {
        margin-right: 30px;
        margin-bottom: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    @include tablet {
      width: auto;
    }

    &__full {
      width: 100%;

      a {
        display: block;
      }
    }
  }
}
