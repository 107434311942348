// Button groups
// --------------------------------------------------

.btn {
  display: inline-block;

  // min-width: 180px;
  // Fixing bug on IOS
  max-height: 44px;

  // End
  vertical-align: middle;
  text-align: center;
  border-radius: 3px;
  line-height: 42px;
  padding: 0 15px;
  color: $color-white;
  transition: background ease 0.25s;
  font-size: $font-size-button;
  user-select: none;
  font-family: $font-family-primary;

  // fix ios tap background
  // -webkit-tap-highlight-color: transparent;

  @extend %truncate;

  @include tablet {
    min-width: 180px;
    max-height: 54px;
    line-height: 52px;
    font-size: $font-size-base;
  }

  &--default {
    color: $color-primary;
    background-color: $color-dark-white;
    border: 1px solid $color-dark-white;

    &:hover {
      background-color: rgba($color-dark-white, 0.8);
    }
  }

  &--primary {
    border: 1px solid $color-primary;
    background-color: $color-primary;

    &:hover {
      background-color: rgba($color-primary, 0.8);
    }
  }

  &--primary-outline {
    border: 1px solid $color-primary;
    background-color: transparent;
    color: $color-primary;

    @include tablet {
      border: 1px solid $color-primary;
      background-color: $color-primary;
      color: $color-white;

      &:hover {
        background-color: rgba($color-primary, 0.8);
      }
    }
  }

  &--grey {
    border: 1px solid $color-suva-grey;
    background-color: $color-suva-grey;
    color: $color-white;

    &:hover {
      background-color: rgba($color-suva-grey, 0.8);
    }
  }

  &--large {
    min-width: 200px;
  }

  &--small {
    min-width: 150px;
  }

  &--center {
    display: block;
    width: 240px;
    margin: 0 auto;
  }

  &--disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
  }

  &--block {
    margin-top: 60px;

    > * {
      flex: 0 1 auto;
      min-width: 148px;
      margin-right: 15px;
    }

    @include tablet {
      margin-top: 120px;

      > * {
        min-width: 180px;
        margin-right: 30px;
      }
    }
  }

  // handle special case for my-page
  &--mypage {
    margin-top: 40px;

    @include tablet {
      margin-top: 60px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__text {
    display: inline-block;
    vertical-align: top;
    width: 150px;

    @extend %truncate;
  }

  &--full {
    width: 100%;
  }

  &--link {
    margin-top: 16px;

    @include tablet {
      color: $color-dodger-blue;
      background: transparent;
      border: none;
      line-height: normal;
      padding: 0;
      margin: 0;
      min-width: auto;

      &:hover {
        color: $color-primary;
        background: transparent;
      }
    }
  }
}
