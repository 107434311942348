
@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.item {
  transition: background-color 0.25s ease;
  text-align: center;
  cursor: pointer;
  font-size: $font-size-small;

  @include screen-sm {
    margin-right: 20px !important;
  }
  @include tablet {
    border-bottom: 0;
    margin-right: 0;
  }

  &:hover {
    background: $color-hover-backgroud;
  }
}

.pcHidden {
  @include tablet {
    display: none;
  }
}

.spHidden {
  display: none;

  @include tablet {
    display: block;
  }
}

.pl-10px {
  padding-left: 10px;
}
