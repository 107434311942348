.change-name {
  @include tablet {
    width: 540px;
    margin: 0 auto;
  }

  .form__input {
    @include tablet {
      width: 255px;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
