.message {
  border: 5px solid $color-alto;
  padding: 20px;
  margin-bottom: 30px;
  background-color: $color-white;

  &__title {
    padding-bottom: 20px;
    color: $color-link;
    font-weight: $font-weight-normal;

    @include tablet {
      font-size: $font-size-large;
    }

    &--gray {
      border-bottom: 2px solid $color-alto;
      background-color: rgba($color-black, 0.05);
    }

    &--small {
      padding: 15px;
    }
  }

  &--seamless {
    border: solid $color-alto;
    border-width: 2px 2px 0;
    padding: 0;
    margin-bottom: 0;

    &:last-child {
      border-bottom: 2px solid $color-alto;
      margin-bottom: 30px;
    }
  }

  &__content {
    line-height: 1.6;
  }
}
