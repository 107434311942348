.user-profile {
  text-align: left;

  &__avatar-wrapper {
    margin-bottom: 60px;
  }

  &__avatar {
    position: relative;
    display: block;
    width: 150px;
    height: 150px;
    background-color: $color-dusty-gray;
    background-position: center center;
    background-size: cover;
    color: $color-white;
    text-align: center;

    input {
      display: none;
    }

    label {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 19px 0;
      width: 100%;
      background-color: $color-grey;
    }

    &:hover {
      label {
        display: inline;
      }
    }
  }

  &__btn-delete {
    position: absolute;
    right: 0;
    background: $color-black;
    color: $color-white;
  }

  &__photo {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__icon {
    padding: 10px 0;
  }

  &__hint {
    color: $color-grey;
    padding-top: 15px;

    @include tablet {
      font-size: 14px;
    }
  }

  &__label {
    color: $color-grey;
    padding-bottom: 10px;

    @include tablet {
      font-size: $font-size-large;
    }
  }
}
