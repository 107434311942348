.faq {
  &__category-list {
    flex-basis: 100%;
    border-top: 1px solid $color-grey-light;

    @include tablet {
      flex-basis: 25%;
    }
  }

  &__body {
    flex-basis: 100%;

    @include tablet {
      flex-basis: 75%;
      padding-left: 50px;
    }
  }

  &__content {
    margin-bottom: 60px;
    padding-left: 22px;
  }

  &__category {
    font-size: 24px;
    padding: 5px 15px;
    margin-bottom: 40px;
    font-weight: $font-weight-bold;
    background-color: $color-background-gray;
  }

  &__numbered {
    position: absolute;
    left: 0;
  }

  &__answer-question {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    padding-left: 4em;
    margin-bottom: 40px;
  }

  .tab-list {
    border-bottom: none;

    &__item {
      display: inline-block;
      margin: 0 0 15px 10px;
      padding: 0;
      opacity: 0.5;

      &--active {
        opacity: 1;
      }
    }
  }
}
