.confirm-success {
  font-size: $font-size-base;
  line-height: 1.5;
  padding: 0;
  background-size: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.confirm-course {
  border: 5px solid $color-alto;
  padding: 20px 90px 20px 20px;

  &__boxes {
    padding-bottom: 10px;
  }

  &__card-title {
    flex-basis: 120px;
    line-height: 1.6;
  }

  &__content {
    flex: 1;
  }

  &__price {
    border-bottom: 1px solid $color-grey-light;
    padding: 5px 0;
  }

  &__money {
    text-align: right;
  }

  &__money-tax {
    display: block;
    font-size: $font-size-base;
  }

  &__period-covered {
    font-size: $font-size-small;
    line-height: 1.6;

    &--trial {
      color: rgba($color-red, 1);
    }
  }

  &__card {
    padding-top: 10px;
  }

  &__card-detail {
    padding-right: 20px;
    width: 110px;
    display: inline-block;
  }
}
