/**
 * Stylesheet importer
 *
 * Variables ...... core variables and mixins
 * Reset .......... css reset
 * Base ........... foundations (e.g. sanitize.css, resets)
 * Components ..... designed, complete components
 * Pages .......... screen detail styles
 * Vendors ........ external libraries
 */

/**
 * Core variables and mixins
 */
@import './abstracts/variables';
@import './abstracts/mixins';

/**
 * Base
 */
@import './base/reset';
@import './base/base';

/**
 * Components
 */
@import './components/button';
@import './components/forms';
@import './components/link';
@import './components/section';
@import './components/message';
@import './components/pagination';
@import './components/products';
@import './components/table';
@import './components/checkbox';
@import './components/tabs';
@import './components/list';
@import './components/icon';
@import './components/title';
@import './components/remind';
@import './components/band';
@import './components/hint';
@import './components/subscription';
@import './components/dropup';
@import './components/breadcrumb';

/**
 * Layout
 */
@import './layout/content';
@import './layout/footer';
@import './layout/grid';

/**
 * Pages
 */
@import './pages/home';
@import './pages/user';
@import './pages/user-profile';
@import './pages/service';
@import './pages/signup-message';
@import './pages/notify';
@import './pages/notify-list';
@import './pages/confirm-success';
@import './pages/term';
@import './pages/not-found';
@import './pages/faq';
@import './pages/payment-history';
@import './pages/contact';
@import './pages/change-name';

/**
 * Utilities
 */
@import './abstracts/utilities';

/**
 * Vendors
 */
@import '~font-awesome/css/font-awesome.min.css';
