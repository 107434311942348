@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.root {
  display: block;
  height: 50px;
  transition: background-color 0.5s ease;
  font-size: $font-size-small;
  line-height: 50px;
  position: relative;

  .button {
    height: 46px;
    border-radius: 5px;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    width: max-content;

    &:hover {
      background: $color-hover-backgroud;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .fullName {
    color: $color-grey;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .name {
      max-width: 150px;
      display: inline-block;
      padding: 0 13px;

      @extend %truncate;
    }
  }

  .menu {
    width: 300px;
    border: 1px solid $color-silver;
    border-radius: 5px;
    background: $color-white;
    box-shadow: 0 3px 5px rgb(0 0 0 / 10%);
    position: absolute;
    right: -10px;
    top: 55px;
    z-index: 99;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }
}
