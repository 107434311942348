.hint {
  color: $color-hint;
  padding-top: 10px;

  @include tablet {
    font-size: $font-size-hint;
  }

  &.hint--error {
    color: $color-red;
  }

  &__text {
    font-size: $font-size-smaller;

    @include tablet {
      font-size: $font-size-hint;
    }
  }
}
