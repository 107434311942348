.main-footer {
  width: 100%;
  color: $color-white;
  background-color: $color-bg-footer;

  &__title,
  &__title > a {
    color: $color-white;
  }

  &__section {
    padding: 12px 0;
    width: 47%;

    @include tablet {
      padding: 8px 0;
    }
  }

  &__head-border {
    border-top: 0;

    @include tablet {
      border-top: 1px solid $color-white;
    }
  }

  &__box {
    margin-bottom: 20px;
    padding-right: 8px;

    @include tablet {
      padding-right: 16px;
    }
  }

  &__head {
    padding-bottom: 10px;
    font-weight: $font-weight-normal;
    font-size: 1.3rem;

    @include tablet {
      font-size: 15px;
    }
  }

  &__body {
    li {
      padding: 5px 0;
    }

    p,
    a {
      color: $color-white;
      font-size: 1.1rem;

      @include tablet {
        font-size: 1.3rem;
      }
    }

    a {
      transition: opacity 0.25s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__link {
    color: $color-white;
    font-size: 1.1rem;

    @include tablet {
      font-size: 1.3rem;
    }
  }

  &__copyright {
    display: block;
    font-size: 11px;
    text-align: center;
    padding: 12px 0;

    @include tablet {
      padding: 20px 0;
    }
  }
}
