.list {
  display: block;

  &__link {
    display: block;
    height: 50px;
    transition: background-color 0.5s ease;
    font-size: $font-size-small;
    line-height: 50px;

    &--medium {
      line-height: 40px;
      font-size: $font-size-base;
    }

    &--black {
      color: $color-black;
    }

    &--active {
      background-color: $color-background-gray;
      cursor: pointer;
    }
  }

  &--horizontal {
    .list__link {
      position: relative;
      border-left: 1px solid $color-alto;
      padding: 0 20px;
    }

    .icon--right {
      color: rgb(51 51 51 / 40%);
      position: absolute;
      right: 20px;
      line-height: 40px;
    }
  }


  &--align-center {
    text-align: center;
  }
}
