.subscription {
  margin-bottom: 30px;

  @include tablet {
    margin-bottom: 60px;
  }

  &--summary {
    margin-bottom: 120px;
  }

  &__list {
    margin-bottom: 20px;

    @include tablet {
      margin-bottom: 50px;
    }
  }

  &__summary {
    text-align: left;

    &--full {
      @include tablet {
        width: 100%;
      }
    }

    @include tablet {
      margin: auto;
      margin-right: 0;

      td {
        padding-bottom: 15px;
      }
    }
  }

  &__hint {
    padding-top: 0;

    @include tablet {
      padding-right: 30px;
    }
  }

  &__total {
    padding-left: 20px;

    @include tablet {
      padding-left: 60px;
    }
  }

  &__line {
    margin: 20px 0;

    @include tablet {
      margin-top: 60px;
    }
  }
}
