.drop-menu {
  position: relative;

  &::after {
    content: '\F107';
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 2rem;
  }

  &--active {
    > .drop-menu__content {
      display: block !important;
    }
  }

  &--language {
    .drop-menu__toggle {
      padding-left: 22px;
    }

    &::before {
      content: '\F0AC';
      font: normal normal normal 14px/1 FontAwesome;
      position: absolute;
      display: block;
      left: 0;
      top: 3px;
      font-size: 2rem;
    }
  }

  &__toggle {
    display: inline-block;
    position: relative;
    vertical-align: top;
    color: $color-white;
    padding-right: 30px;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    min-width: 160px;
    display: none;
    left: 0;
    top: auto;
    bottom: 28px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 15%);
    border-radius: 3px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);

    &::after {
      content: '';
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      bottom: -8px;
      left: 20px;
    }
  }

  &__link {
    display: block;
    cursor: pointer;
    padding: 10px 20px;
    color: #000;
    white-space: nowrap;
    line-height: 1.4;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
