@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navs {
    display: flex;
    align-items: center;
  }

}

.spSideMenu {
  .spMenuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.85);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s linear;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

}
