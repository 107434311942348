.notify {
  &__item {
    display: block;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-grey-light;
  }

  &__header {
    font-size: $font-size-small;
    padding-bottom: 20px;
    position: relative;
  }

  &__date {
    width: 100px;
    top: 3px;
    position: absolute;
  }

  &__category {
    background-color: rgba($color-black, 0.4);
    color: $color-white;
    padding: 2px 15px;
    font-size: $font-size-base;
    margin-top: 15px;
    margin-left: 100px;
  }

  &__body {
    padding-left: 100px;
    line-height: 1.7;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
  }
}
