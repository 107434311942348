// Components :: Title
// --------------------------------------------------

.title {
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  color: $color-grey;

  @include tablet {
    font-size: $font-size-title;
  }

  &--primary {
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 45px;
    }
  }

  &--full {
    width: 100%;
  }

  &--sub {
    margin-bottom: 16px;
    margin-top: 5px;
    color: $color-sub-title;
    font-weight: $font-weight-normal;
    font-size: $font-size-large;

    @include tablet {
      margin-bottom: 20px;
      margin-top: 10px;
      font-size: $font-size-sub-title;
    }
  }

  &--small {
    line-height: 20px;

    @include tablet {
      font-size: $font-size-base;
    }
  }

  &--large {
    font-size: $font-size-base;

    @include tablet {
      font-size: $font-size-large;
    }
  }

  &--larger {
    font-size: $font-size-extra;

    @include tablet {
      font-size: $font-size-larger;
    }
  }

  &--big {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;

    @include tablet {
      font-size: $font-size-big;
    }
  }
}
