/**
 * Abstracts :: Minxins
 */

/**
 * Truncate oneline
 */

%truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
 * Truncate text multiline
 *
 * @param {string} $line-height - Line height of text
 * @param {number} $line-count - Number of line
 * @param {string} $bg-color - Background color of text
 */

@mixin truncate-multiline(
  $line-height: 1.2em,
  $line-count: 1,
  $bg-color: $white
) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: $line-height * $line-count;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bg-color;
  }
}

@mixin tablet {
  @media only screen and (min-width: #{$screen-sm}) {
    @content;
  }
}


@mixin screen-sm {
  @media only screen and (max-width: #{$screen-sm}) {
    @content;
  }
}
