// Components :: Tables
// --------------------------------------------------

.base-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  background-color: $color-white;
  border: 2px solid $color-background-gray;

  &__col-name {
    padding: 15px;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
    text-align: left;
    border: 2px solid $color-background-gray;

    &--bg-gray {
      background: rgba($color-black, 0.03);
    }
  }

  &__col-w400 {
    @include tablet {
      width: 400px;
    }
  }

  &__col--hidden {
    display: none;
  }

  &__content {
    padding: 10px;
    border: 2px solid $color-background-gray;
    vertical-align: middle;
  }

  &__content-wide {
    padding: 15px;
  }

  &__text--bolder {
    font-weight: $font-weight-bold;
  }

  &__text--blue {
    color: $color-link;
  }

  &__button {
    vertical-align: middle;
    text-align: center;
  }

  &--fixed {
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
  }

  &--primary {
    .base-table {
      &__col-name {
        border: 2px solid $color-link;
        background-color: $color-link;
        color: $color-white;
      }
    }
  }
}
