.form {
  &--default {
    max-width: 540px;
    margin: 0 auto;
  }

  &__wrapper {
    display: block;
    margin-bottom: 30px;

    &--relative {
      position: relative;
    }
  }

  &__label {
    text-align: left;
    display: block;
    padding-bottom: 13px;
    color: $color-grey;

    @include tablet {
      font-size: $font-size-small;
    }
  }

  &__input {
    width: 100%;
    height: 44px;
    padding: 15px 25px;
    border: 1px solid $color-gray;
    background-color: $color-white;
    font-size: $font-size-large;
    border-radius: 3px;
    box-shadow: inset 0 0 5px 0 rgba($color-black, 0.1);
    transition: all 0.3s;

    &:hover,
    &:focus {
      border-color: #40a9ff;
    }

    @include tablet {
      height: 54px;
    }

    &--sm {
      max-width: 125px;
    }

    &--md {
      @include tablet {
        max-width: 255px;
      }
    }

    &::placeholder {
      color: rgba($color-grey, 0.4);
    }
  }

  &__text-area {
    width: 100%;
    font-size: $font-size-large;
    border-radius: 3px;
    padding: 30px;
    box-shadow: inset 0 0 5px 0 rgba($color-black, 0.1);

    &--big {
      min-height: 240px;
    }
  }

  &__slash {
    padding: 0 16px;

    @include tablet {
      padding: 0 20px;
    }
  }
}
