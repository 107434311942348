.pagination {
  &__list {
    max-width: 480px;
    margin: 0 auto;
  }

  &__item {
    background-color: $color-concrete;
    border: solid 1px $color-gray;
    border-radius: 3px;
    transition: background-color 0.25s;

    &:hover {
      background-color: $color-french-gray;
      cursor: pointer;
    }

    &--active {
      background-color: $color-french-gray;

      .pagination__button {
        color: $color-white;
      }
    }

    &--disabled {
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
        cursor: not-allowed;
      }

      .pagination__button {
        color: rgba($color-grey, 0.4);
      }
    }
  }

  &__button {
    display: table-cell;
    padding: 0 20.5px;
    color: $color-grey;
    line-height: 52px;
  }
}
