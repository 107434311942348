.tab-list {
  padding-left: 0;

  &__item {
    display: inline-block;
    list-style: none;
    margin: 13px 15px 13px 0;
    overflow: hidden;

    @include tablet {
      margin: 20px 20px 20px 0;
    }

    &__btn {
      height: 32px;
      width: 80px;
      text-align: center;
      font-size: 14px;
      line-height: 28px;
      border-radius: 400px;
      background: transparent;
      color: $color-suva-grey;
      display: block;
      cursor: pointer;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        line-height: 32px;
      }

      &--active {
        border: solid 1px $color-primary;
        color: $color-primary;
      }

      @include tablet {
        height: 26px;
        line-height: 22px;

        &--active {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
}
