@import '@/styles/abstracts/_variables';
@import '@/styles/abstracts/_mixins';

.root {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 86%;
  right: 0;
  background: $color-dark-white;
  transform: translateX(100%);
  transition: 0.25s;
  z-index: $zindex-menu-bar;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block;
  -webkit-overflow-scrolling: touch;

  @include tablet {
    // strictly not display on desktop
    display: none !important;
  }

  @include tablet {
    transform: initial;
    position: static;
    display: flex;
    background: transparent;
    height: auto;
    max-width: none;
    width: auto;
  }

  .list {
    width: 100%;
    height: 100%;
    display: block;

    @include tablet {
      display: flex;
      align-items: center;
    }
  }

  .item {
    border-bottom: 1px solid $color-silver;
    line-height: 50px;
    transition: background-color 0.25s ease;

    &:hover {
      cursor: pointer;
      background: rgba($color-black, 0.05);
    }

    @include tablet {
      border-bottom: 0;
    }

    &.button {
      padding: 16px;

      &:hover {
        background: none;
      }
    }
  }

  .link {
    line-height: 1;
    display: block;
    padding: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-black;
    font-size: $font-size-small;

    @include tablet {
      color: $color-white;
    }

    &.avater {
      display: flex;
      justify-content: flex-start;
    }
  }

  &.active {
    transition: 0.25s;
    transform: none;
    box-shadow: 0 2px 6px 0 rgba($color-black, 0.42);
  }
}
